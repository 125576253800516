

@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
    font-family: 'Roboto', sans-serif;
 line-height: 1.5;
 letter-spacing: 0.5px;
padding: 0;
margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
 margin: 0;
}

ul,
ol {
 margin: 0;
 padding: 0;
}
li{list-style: none;}

img {
 display: block;
 max-width: 100%;
 height: auto;
}
a {
    text-decoration: none;
    color: black;
}