.ourBenefits {
  padding: 70px 0 70px 0;
  text-align: center;
  background-color: #f0f0f0;
}
.ourBenefits-list{
    text-align: center;
    display: inline-block;
    max-width: 900px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}
.ourBenefits-item{ margin-top: 30px;
    justify-content: center;
padding: 20px;}
   
.ourBenefits-img{  display: inline-block;
    padding: 10px 0;
    width:120px;
    margin-left: auto;
    margin-right: auto;}

    .ourBenefits-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    margin-top: 30px;
  }
  .ourBenefits-text {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7;
    margin-top: 30px;
    max-width: 300px;
  }




  @media only screen and (min-width: 1020px) {
    .ourBenefits-list{ 
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }
    .ourBenefits-item-color{
        background-color: white;
    }
     
} 