.modalBox {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 7%;
  width: 300px;
  max-height: 85%;
  overflow-y: auto;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
  padding: 10px 15px 40px 15px
}
.modalLink{
  position: absolute;
  top: 15px;
  display: flex;
  opacity: 20%;
}
.modalLinkText{
 font-size: smaller;
}
.modalButtonClose {
  display: block;
  margin-left: auto;
  cursor: pointer;
}

.shoppingList-title {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.shoppingList-product {
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
  margin-bottom: 85px;
}

.shoppingList-productName {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 30px;
}

.shoppingList-quantity {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
  padding: 5px;
}

.shoppingList-productPrice {
  flex-grow: 1;
  text-align: right;
  font-size: 14px;
}
.modalSelectSize{
display: flex;
justify-content:space-between
}
.modalSelect{
  border: 2px solid black;
  border-radius: 5px;
  font-weight: bold;
}
.shoppingList-deleteIcon {
  margin-left: auto;
  cursor: pointer;
}

.shoppingList-totalPrice {
  margin-top: 50px;
  text-decoration: underline;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.shoppingList-form {
  margin-top: 20px;
}

.shoppingList-form label {
  display: block;
  margin-bottom: 10px;
}

.shoppingList-form input,
.shoppingList-form textarea {
  padding: 8px;
  margin-top: 10px;
  box-sizing: border-box;
}
.shoppingList-formInput {
  margin-bottom: 10px;
  width: 100%;
}
.shoppingList-payment {
  width: 5%;
  box-sizing: inherit;
}
.shoppingList-checkbox {
  margin-top: 40px;
  border-top: 1px solid #ccc;}

.shoppingList-form p {
  font-weight: bold;
  margin-bottom: 10px;
}
.shoppingList-paymentTitle {
  margin-top: 40px;
}
.shoppingList-payment {
  margin-right: 25px;
}

.shoppingList-questionInput {
  height: 70px;
  width: 100%;
  }

.shoppingList-questionInput::placeholder {
  white-space: pre-line;
}

.shoppingList-form button {
  cursor: pointer;
  border-radius: 25px;
  color: #000;
  background-color: #f5c231;
  width: 100%;
  height: 40px;
  margin-top: 40px;
  font-weight: bold;
font-size: 18px;
}

.shoppingList-form button:hover { background-color: #ff671e;
  color: white;
}

@media only screen and (min-width: 1020px) {
  .modalBox {
 
    width: 620px;
    
    padding: 30px 30px 40px 30px
  }
  .modalLink{
    top: 37px;
  }

}