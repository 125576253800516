.feadback {
    padding: 70px 0 70px 0;
    text-align: center;
    background-color: #ddd2be;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
  .feadback-list{
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
  }
  .feadback-text{
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .feadback-text2{
    font-size: 24px;
    margin-bottom: 30px;
  }
  .feadback-form{
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  .feadback-input{
    width: 200px;
    height: 30px;
    padding: 10px 5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .feadback-button{
    width: 300px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    background-color: #f5c231;
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
  }

  @media only screen and (min-width: 1020px) {
    .feadback-form{ flex-direction: row;
        justify-content: center; }
        .feadback-button{
          margin-top: 0;
  }}