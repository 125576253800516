.container{
    margin-left: auto;
    margin-right: auto;
}
button{cursor: pointer;
border-radius: 25px;
}


button:hover{
   background-color: #ff671e;
color: white;
}