.proposal-section {
  margin-top: 400px;
  padding-bottom: 50px;
}
.proposal-list {
  display: flex;
  padding: 10px 10px 20px 10px;
  gap: 120px;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.proposal-item {
  position: relative;
  z-index: 3;
}
.proposal-item-text {
  margin-top: 30px;
  font-size: 25px;
  text-indent: 2ch;
}
.proposal-item::before {
  display: block;
  content: '';
  transform: translate(-5%, -3%);
  background-color: #eedec7;
  width: 95%;
  height: 110%;
  z-index: -1;
  position: absolute;
  left: 0;
  padding: 0 25px;
}
.proposal-discount {
  position: relative;
  display: block;
  background-image: url('../../Images/prices1.png');
  background-size: cover;
  background-position: center;
  margin: 100px auto 50px auto;
  min-height: 80px;
  text-align: center;
}
.proposal-discountPercent {
  position: absolute;
  font-size: 80px;
  font-style: oblique;
  color: #67080a;
  transform: translate(60%, -65%);
}
.proposal-discount-text {
  display: inline-block;
  margin: 0 auto;
  max-width: 280px;
  padding-top: 15px;
  font-weight: bold;
  color: #67080a;
}

.proposal-img {
  border: 7px solid white;
  width: 320px;
}
.proposal-text {
  text-align: center;
  font-size: 28px;
  margin-top: 30px;
}
.proposal-button {
  display: block;
  margin: 70px auto 50px auto;
  width: 300px;
  height: 50px;
  background-color: #f5c231;
  font-size: 26px;
  font-weight: bold;
}

@media only screen and (min-width: 480px) {
  .proposal-allPrice {
    margin-top: 100px;
    width: 470px;
  }
  .proposal-discount {
    width: 480px;
    margin: 110px auto 50px auto;
  }
  .proposal-discountPercent {
    transform: translate(80%, -65%);
  }
  .proposal-discount-text {
    max-width: 380px;
    font-size: 24px;
  }

  .price-text {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1020px) {
  .proposal-section {
    margin-top:0px;
    margin-bottom: 70px;
  }
  .proposal-discount {
  
    margin-top: 0;
  }
  .proposal-list {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 200px 30px 200px 30px;
  }
  .proposal-discount {
    width: 800px;
  }
  .proposal-discountPercent {
    transform: translate(170%, -65%);
  }
  .proposal-discount-text {
    max-width: 380px;
    font-size: 24px;
  }
  .price-text {
    font-size: 35px;
  }
}
