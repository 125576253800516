.productCard {
 
  padding: 15px 0px 75px 0px;
  margin-left: auto;
  margin-right: auto;
}

.productType {
  background-color: #f0f0f0;
  text-align: center;
  margin-top: 30px;
  font-size: 26.8px;

  padding: 30px 0;
}
.productCardSwiper {
  margin-top: 45px;
 }
 


.productCardSwiperColor {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  width: 300px;
  padding-bottom: 50px;
  border-bottom: 2px solid #000;
  }
 
.priceText{
  margin-top: 25px;
 padding: 0 15px;
  font-size: 17px
}
.price { 
  font-size: 17px;
   margin-left: 10px;
  text-decoration: line-through;
  font-weight: bold;
  
 }
.todayPrice {
  color: #ff671e;
  font-size: 17px;
  text-decoration-line: underline;
  font-weight: bold;
margin-left: 10px;
}
.productOptions{display: flex;
  gap: 15px;
  margin-top: 35px;
  font-size: 19px;
  font-weight: bold;
  flex-wrap: wrap;
  padding: 0 15px;
}
.optionSelect{
  text-align: center;
  margin-left: 9px;
}
.productCardButton{
  display: block;
  cursor: pointer;
  border-radius: 25px;
  color: #000;
  background-color: #f5c231;
 max-width: 500px;
 margin-left: auto;
 margin-right: auto;
  height: 40px;
  margin-top: 45px;
  font-weight: bold;
font-size: 18px;
}
.aboutTitle{
  margin-top: 95px;
  font-size: 22px;
  font-weight: bold;
  font-style: oblique;
  line-height: 35px;
  padding: 0 15px;
}
.aboutText{
  margin-top: 40px;
  line-height: 35px;
  font-size: 18px;
  padding: 0 15px;
}
.sizeTitle{
  margin-bottom: 50px;
text-align: center;
font-size: 32px;
font-weight: bold;
}
.sizesTable{
  background-color: #f0f0f0;
  padding: 50px 0;
  margin-top: 60px;
}
.productSize-table {
  margin: 20px auto 10px auto;
  background-color: #ffffff;
  padding: 3px;
}
.productSize-textAfter{
  text-align: center;
}
.productSize-bolt {
  font-weight: bold;
  font-size: 25px;
}
th,
td {
  font-size: 14.5px;
  padding: 6px 5px;
  text-align: center;
  border: 1px solid #ddd;
}


@media only screen and (min-width: 1020px) {
  .modalBox {
    width: 620px;

    padding: 30px 30px 40px 30px;
  }
  .sizesTable{
  
    margin-bottom: 90px;
  }
}
