.footer{
    background-color: black;
    padding: 50px;
    color: white;
    
    text-align: center;
}
.footer-list{
    margin-bottom: 60px;
}
.footer-text{
    color: white;
}
@media only screen and (min-width: 900px) {
    .footer-list{
       display: flex;
       gap: 40px;
       justify-content: center;
    }

}