.backLink{
    position: absolute;
    top: 25px;
}

.agreementContainer{
    padding:50px 7%;
}
.agreementTitles{
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
}
.agreementSite{
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
}