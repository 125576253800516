.countdown-display {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.5rem;
    color:rgb(255, 103, 30);
  
  }
  
  .countdown-timer {
    display: flex;
  }
  
  .countdown-unit {
    margin: 0 5px;
  }
  .countdown-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    color:rgb(255, 103, 30);
    gap: 55px;
  }